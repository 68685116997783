<template>
  <ion-page>
    <ion-header :translucent="true" class="ion-no-border">
      <ion-toolbar>
        <ion-title>Login</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div id="container">
        <ion-img id="logo" src="assets/images/dokmaai.png"></ion-img>
        <div id="login-container">
          <ion-item>
            <ion-label position="fixed">Username</ion-label>
            <ion-input v-model="username" clear-input></ion-input>
          </ion-item>

          <ion-item>
            <ion-label position="fixed">Password</ion-label>
            <ion-input
              type="password"
              v-model="password"
              clear-input
            ></ion-input>
          </ion-item>
          <ion-text id="info"
            >*This would be your Magento administrator credentials</ion-text
          >
          <ion-button
            :disabled="loading"
            @click="login"
            id="login-button"
            expand="block"
            >Login <ion-spinner name="lines-small" v-if="loading"></ion-spinner
          ></ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonButton,
  IonImg,
  IonText,
  IonItem,
  IonLabel,
  alertController,
  IonSpinner,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import { API_URL } from "../providers/constants";
import { Storage } from "@capacitor/storage";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonButton,
    IonImg,
    IonText,
    IonItem,
    IonLabel,
    IonSpinner,
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    login() {
      this.loading = true;

      const loginEndpoint = API_URL + "/rest/V1/integration/admin/token";

      const loginDetails = {
        username: this.username,
        password: this.password,
      };

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      axios
        .post(loginEndpoint, loginDetails, { headers })
        .then((response) => {
          this.loginSuccess(response.data);
          this.loading = false;
        })
        .catch((error) => {
          if (error.response) this.loginError();
          this.loading = false;
        });
    },
    async loginError() {
      const alert = await alertController.create({
        cssClass: "login-error",
        header: "Error",
        subHeader: "",
        message: "Incorrect login credentials",
        buttons: ["OK"],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    loginSuccess(response) {
      const userId = this.username;

      this.storeLogin("token", response);
      this.storeLogin("userId", userId);

      this.$router.push({
        name: "Home",
        params: { userId: this.username, token: response },
        replace: true,
      });
    },
    async storeLogin(key, value) {
      Storage.set({
        key: key,
        value: value,
      });
    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 16px;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
#logo {
  margin-bottom: 40px;
}
#login-container {
  margin-bottom: 20px;
}
#info {
  font-size: 10px;
}
#login-button {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>